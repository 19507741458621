/* tslint:disable */
/* eslint-disable */
/**
 * DriverFrontOfficeApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.91.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Access
 */
export interface Access {
    /**
     * 
     * @type {Allocation}
     * @memberof Access
     */
    allocation?: Allocation;
    /**
     * 
     * @type {Interval}
     * @memberof Access
     */
    carEntry?: Interval;
    /**
     * 
     * @type {Interval}
     * @memberof Access
     */
    carExit?: Interval;
    /**
     * 
     * @type {Interval}
     * @memberof Access
     */
    pedestrian?: Interval;
    /**
     * 
     * @type {boolean}
     * @memberof Access
     */
    isZenithParking?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Access
     */
    hasDigicode?: boolean;
}
/**
 * 
 * @export
 * @interface AccessResponse
 */
export interface AccessResponse {
    /**
     * The response title
     * @type {string}
     * @memberof AccessResponse
     */
    title?: string | null;
    /**
     * The response message
     * @type {string}
     * @memberof AccessResponse
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface AccessResult
 */
export interface AccessResult {
    /**
     * 
     * @type {Access}
     * @memberof AccessResult
     */
    access?: Access;
    /**
     * 
     * @type {boolean}
     * @memberof AccessResult
     */
    hasUpcomingAllocation?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AlertMessageLevel {
    Info = 'Info',
    Warning = 'Warning',
    Danger = 'Danger'
}

/**
 * 
 * @export
 * @interface Allocation
 */
export interface Allocation {
    /**
     * 
     * @type {string}
     * @memberof Allocation
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Allocation
     */
    localFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof Allocation
     */
    localTo?: string | null;
    /**
     * 
     * @type {SpaceTypes}
     * @memberof Allocation
     */
    spaceType: SpaceTypes;
    /**
     * 
     * @type {boolean}
     * @memberof Allocation
     */
    isPrmAdapted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Allocation
     */
    hasChargingPoint?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Allocation
     */
    parkingId?: string;
}
/**
 * 
 * @export
 * @interface ApiErrorResponse
 */
export interface ApiErrorResponse {
    /**
     * The error title
     * @type {string}
     * @memberof ApiErrorResponse
     */
    title?: string | null;
    /**
     * The error message
     * @type {string}
     * @memberof ApiErrorResponse
     */
    message?: string | null;
    /**
     * The error code (for internal use)
     * @type {string}
     * @memberof ApiErrorResponse
     */
    errorCode?: string | null;
    /**
     * 
     * @type {AppAction}
     * @memberof ApiErrorResponse
     */
    action?: AppAction;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AppAction {
    ShowError = 'ShowError',
    Logout = 'Logout',
    ResetStorage = 'ResetStorage',
    Restart = 'Restart'
}

/**
 * Application latest version and limit date for old version to work.
 * @export
 * @interface AppMinimalVersionConfigurationModel
 */
export interface AppMinimalVersionConfigurationModel {
    /**
     * The mininum version.
     * @type {string}
     * @memberof AppMinimalVersionConfigurationModel
     */
    version?: string | null;
    /**
     * The date and time at which versions with a lower number than Zenpark.Paco.DriverFrontOfficeApi.Configuration.Models.AppMinimalVersionConfigurationModel.Version won\'t work anymore.  A null value does not oblige member to install last version.
     * @type {string}
     * @memberof AppMinimalVersionConfigurationModel
     */
    limitDate?: string | null;
}
/**
 * 
 * @export
 * @interface AuthTicket
 */
export interface AuthTicket {
    /**
     * The refresh token
     * @type {string}
     * @memberof AuthTicket
     */
    refreshToken?: string | null;
    /**
     * The access token
     * @type {string}
     * @memberof AuthTicket
     */
    accessToken?: string | null;
    /**
     * Access token expiry with timezone
     * @type {string}
     * @memberof AuthTicket
     */
    expiresIn?: string;
    /**
     * MemberExternalId of the user used for push notifications
     * @type {string}
     * @memberof AuthTicket
     */
    pushNotifMemberExternalId?: string | null;
    /**
     * The identity token
     * @type {string}
     * @memberof AuthTicket
     */
    identityToken?: string | null;
}
/**
 * 
 * @export
 * @interface BeeMyFlexConfigurationModel
 */
export interface BeeMyFlexConfigurationModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof BeeMyFlexConfigurationModel
     */
    connectedCompanyIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof BeeMyFlexConfigurationModel
     */
    appUrl?: string | null;
}
/**
 * 
 * @export
 * @interface CancelReservationResponse
 */
export interface CancelReservationResponse {
    /**
     * The response title
     * @type {string}
     * @memberof CancelReservationResponse
     */
    title?: string | null;
    /**
     * The response message
     * @type {string}
     * @memberof CancelReservationResponse
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface ChangeCultureRequest
 */
export interface ChangeCultureRequest {
    /**
     * 
     * @type {Culture}
     * @memberof ChangeCultureRequest
     */
    newCulture: Culture;
}
/**
 * 
 * @export
 * @interface ChangeCultureResponse
 */
export interface ChangeCultureResponse {
    /**
     * The response message
     * @type {string}
     * @memberof ChangeCultureResponse
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * The company\'s Id.
     * @type {string}
     * @memberof Company
     */
    id?: string;
    /**
     * The company\'s logo url.
     * @type {string}
     * @memberof Company
     */
    logoUrl?: string | null;
    /**
     * The company\'s name
     * @type {string}
     * @memberof Company
     */
    name?: string | null;
    /**
     * 
     * @type {CompanyConfigForDriverInfo}
     * @memberof Company
     */
    config?: CompanyConfigForDriverInfo;
}
/**
 * 
 * @export
 * @interface CompanyAlertMessage
 */
export interface CompanyAlertMessage {
    /**
     * 
     * @type {string}
     * @memberof CompanyAlertMessage
     */
    contentFr: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAlertMessage
     */
    contentEn: string;
    /**
     * 
     * @type {AlertMessageLevel}
     * @memberof CompanyAlertMessage
     */
    level: AlertMessageLevel;
}
/**
 * 
 * @export
 * @interface CompanyConfig
 */
export interface CompanyConfig {
    /**
     * The company unique identifier
     * @type {string}
     * @memberof CompanyConfig
     */
    companyId?: string;
    /**
     * The company name
     * @type {string}
     * @memberof CompanyConfig
     */
    companyName?: string | null;
    /**
     * The description to display on the company landing page
     * @type {string}
     * @memberof CompanyConfig
     */
    landingPageDescription?: string | null;
    /**
     * Is the driver number required
     * @type {boolean}
     * @memberof CompanyConfig
     */
    isDriverNumberRequired?: boolean;
    /**
     * Can the driver register from himself
     * @type {boolean}
     * @memberof CompanyConfig
     */
    isRegistrationAllowed?: boolean;
    /**
     * The url of the company illustration
     * @type {string}
     * @memberof CompanyConfig
     */
    companyIllustrationUrl?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyConfigForDriverInfo
 */
export interface CompanyConfigForDriverInfo {
    /**
     * 
     * @type {FieldEditionParam}
     * @memberof CompanyConfigForDriverInfo
     */
    registrationPlateEditionParam?: FieldEditionParam;
}
/**
 * 
 * @export
 * @interface CompanySsoConfig
 */
export interface CompanySsoConfig {
    /**
     * 
     * @type {SsoProtocol}
     * @memberof CompanySsoConfig
     */
    protocol?: SsoProtocol;
    /**
     * 
     * @type {SsoProvider}
     * @memberof CompanySsoConfig
     */
    provider?: SsoProvider;
    /**
     * The url of SSO issuer
     * @type {string}
     * @memberof CompanySsoConfig
     */
    issuer?: string | null;
    /**
     * The url of the SSO authorize endpoint
     * @type {string}
     * @memberof CompanySsoConfig
     */
    authorizationEndpoint?: string | null;
    /**
     * The SSO credential \"ClientId\"
     * @type {string}
     * @memberof CompanySsoConfig
     */
    clientId?: string | null;
    /**
     * The SSO scopes
     * @type {Array<string>}
     * @memberof CompanySsoConfig
     */
    scopes?: Array<string> | null;
    /**
     * Using PKCE
     * @type {boolean}
     * @memberof CompanySsoConfig
     */
    usingPkce?: boolean;
    /**
     * AuthenticationChannel
     * @type {string}
     * @memberof CompanySsoConfig
     */
    authenticationChannel?: string | null;
}
/**
 * 
 * @export
 * @interface ConfigurationModel
 */
export interface ConfigurationModel {
    /**
     * 
     * @type {AppMinimalVersionConfigurationModel}
     * @memberof ConfigurationModel
     */
    appMinimalVersion?: AppMinimalVersionConfigurationModel;
    /**
     * The token used to call test controller
     * @type {string}
     * @memberof ConfigurationModel
     */
    pacoTestsAuthToken?: string | null;
    /**
     * 
     * @type {BeeMyFlexConfigurationModel}
     * @memberof ConfigurationModel
     */
    beeMyFlex?: BeeMyFlexConfigurationModel;
}
/**
 * 
 * @export
 * @interface CreateAccountRequest
 */
export interface CreateAccountRequest {
    /**
     * Activation account token
     * @type {string}
     * @memberof CreateAccountRequest
     */
    token: string;
    /**
     * User email
     * @type {string}
     * @memberof CreateAccountRequest
     */
    email: string;
    /**
     * User new password
     * @type {string}
     * @memberof CreateAccountRequest
     */
    newPassword: string;
    /**
     * User confirmation password, should be the same value than Password
     * @type {string}
     * @memberof CreateAccountRequest
     */
    newPasswordConfirm: string;
    /**
     * Does user opted-in for marketing communications
     * @type {boolean}
     * @memberof CreateAccountRequest
     */
    marketingOptin: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CreationOrigin {
    ManagerInput = 'ManagerInput',
    ManagerImport = 'ManagerImport',
    DriverRequest = 'DriverRequest',
    DriverAutoRegistration = 'DriverAutoRegistration',
    Sso = 'Sso',
    AdminInput = 'AdminInput'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum Culture {
    En = 'En',
    Fr = 'Fr'
}

/**
 * 
 * @export
 * @interface DriverInfo
 */
export interface DriverInfo {
    /**
     * The driver\'s identifier in his company (\"matricule\" in french).
     * @type {string}
     * @memberof DriverInfo
     */
    number?: string | null;
    /**
     * The driver\' first name.
     * @type {string}
     * @memberof DriverInfo
     */
    firstName?: string | null;
    /**
     * The driver\'s last name.
     * @type {string}
     * @memberof DriverInfo
     */
    lastName?: string | null;
    /**
     * The driver\'s email.
     * @type {string}
     * @memberof DriverInfo
     */
    email?: string | null;
    /**
     * The driver\'s phone number.
     * @type {string}
     * @memberof DriverInfo
     */
    phone?: string | null;
    /**
     * Whether the driver is a person with reduced mobility.
     * @type {boolean}
     * @memberof DriverInfo
     */
    isPrm?: boolean;
    /**
     * The driver\'s Zenith passcode. This property is deprecated. Use the formattedPassword property instead.
     * @type {number}
     * @memberof DriverInfo
     */
    passcode?: number | null;
    /**
     * The driver\'s Zenith passcode.
     * @type {string}
     * @memberof DriverInfo
     */
    formattedPasscode?: string | null;
    /**
     * 
     * @type {SpaceAllocationType}
     * @memberof DriverInfo
     */
    spaceAllocationType?: SpaceAllocationType;
    /**
     * List of parkings that the driver has access to.
     * @type {Array<Parking>}
     * @memberof DriverInfo
     */
    parkings?: Array<Parking> | null;
    /**
     * 
     * @type {Company}
     * @memberof DriverInfo
     */
    company?: Company;
    /**
     * 
     * @type {UserType}
     * @memberof DriverInfo
     */
    userType?: UserType;
    /**
     * 
     * @type {Culture}
     * @memberof DriverInfo
     */
    culture?: Culture;
    /**
     * 
     * @type {CreationOrigin}
     * @memberof DriverInfo
     */
    creationOrigin?: CreationOrigin;
}
/**
 * 
 * @export
 * @interface DriverLight
 */
export interface DriverLight {
    /**
     * 
     * @type {string}
     * @memberof DriverLight
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DriverLight
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DriverLight
     */
    email?: string | null;
    /**
     * 
     * @type {UserStatus}
     * @memberof DriverLight
     */
    status?: UserStatus;
}
/**
 * 
 * @export
 * @interface DriverRegistrationRequest
 */
export interface DriverRegistrationRequest {
    /**
     * The company unique identifier
     * @type {string}
     * @memberof DriverRegistrationRequest
     */
    companyId: string;
    /**
     * The driver first name
     * @type {string}
     * @memberof DriverRegistrationRequest
     */
    firstName: string;
    /**
     * The driver last name
     * @type {string}
     * @memberof DriverRegistrationRequest
     */
    lastName: string;
    /**
     * The driver email address
     * @type {string}
     * @memberof DriverRegistrationRequest
     */
    email: string;
    /**
     * The driver phone number
     * @type {string}
     * @memberof DriverRegistrationRequest
     */
    phone?: string | null;
    /**
     * The driver id number
     * @type {string}
     * @memberof DriverRegistrationRequest
     */
    number?: string | null;
    /**
     * Whether the driver is a person with reduced mobility.
     * @type {boolean}
     * @memberof DriverRegistrationRequest
     */
    isPrm: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldEditionParam {
    Disabled = 'Disabled',
    Optional = 'Optional',
    Mandatory = 'Mandatory'
}

/**
 * 
 * @export
 * @interface ForgottenPasswordRequest
 */
export interface ForgottenPasswordRequest {
    /**
     * Driver\'s email
     * @type {string}
     * @memberof ForgottenPasswordRequest
     */
    email: string;
}
/**
 * 
 * @export
 * @interface ForgottenPasswordResponse
 */
export interface ForgottenPasswordResponse {
    /**
     * The response title
     * @type {string}
     * @memberof ForgottenPasswordResponse
     */
    title?: string | null;
    /**
     * The response message
     * @type {string}
     * @memberof ForgottenPasswordResponse
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface Interval
 */
export interface Interval {
    /**
     * 
     * @type {string}
     * @memberof Interval
     */
    localFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof Interval
     */
    localTo?: string | null;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * Member\'s email
     * @type {string}
     * @memberof LoginRequest
     */
    email: string;
    /**
     * Member\'s password
     * @type {string}
     * @memberof LoginRequest
     */
    password: string;
}
/**
 * 
 * @export
 * @interface NewReservation
 */
export interface NewReservation {
    /**
     * 
     * @type {string}
     * @memberof NewReservation
     */
    localFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof NewReservation
     */
    localTo?: string;
    /**
     * 
     * @type {SpaceTypes}
     * @memberof NewReservation
     */
    spaceType?: SpaceTypes;
    /**
     * 
     * @type {boolean}
     * @memberof NewReservation
     */
    chargingPointRequired?: boolean;
    /**
     * Does a space for person with reduced mobility required
     * @type {boolean}
     * @memberof NewReservation
     */
    prmSpaceRequired?: boolean;
    /**
     * Parking identifier
     * @type {string}
     * @memberof NewReservation
     */
    parkingId?: string;
    /**
     * Vehicle identifier
     * @type {string}
     * @memberof NewReservation
     */
    vehicleId?: string;
}
/**
 * 
 * @export
 * @interface NewVehicle
 */
export interface NewVehicle {
    /**
     * Car name
     * @type {string}
     * @memberof NewVehicle
     */
    name: string;
    /**
     * 
     * @type {SpaceTypes}
     * @memberof NewVehicle
     */
    type: SpaceTypes;
    /**
     * Is eletrical vehicle
     * @type {boolean}
     * @memberof NewVehicle
     */
    isEv: boolean;
    /**
     * The vehicle registration plate
     * @type {string}
     * @memberof NewVehicle
     */
    registrationPlate?: string | null;
    /**
     * The vehicle registration plate country
     * @type {string}
     * @memberof NewVehicle
     */
    registrationPlateCountry?: string | null;
}
/**
 * 
 * @export
 * @interface Parking
 */
export interface Parking {
    /**
     * The parking\'s Id.
     * @type {string}
     * @memberof Parking
     */
    id?: string;
    /**
     * The parking\'s name
     * @type {string}
     * @memberof Parking
     */
    name?: string | null;
    /**
     * The parking\'s location, defining the address and details of the parking
     * @type {string}
     * @memberof Parking
     */
    location?: string | null;
    /**
     * The parking\'s location latitude
     * @type {number}
     * @memberof Parking
     */
    latitude?: number;
    /**
     * The parking\'s location longitude
     * @type {number}
     * @memberof Parking
     */
    longitude?: number;
    /**
     * 
     * @type {SpaceAllocationType}
     * @memberof Parking
     */
    spaceAllocationType?: SpaceAllocationType;
}
/**
 * 
 * @export
 * @interface PlanningDay
 */
export interface PlanningDay {
    /**
     * 
     * @type {Array<PlanningItem>}
     * @memberof PlanningDay
     */
    items?: Array<PlanningItem> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PlanningDay
     */
    isFull?: boolean;
}
/**
 * 
 * @export
 * @interface PlanningItem
 */
export interface PlanningItem {
    /**
     * 
     * @type {string}
     * @memberof PlanningItem
     */
    localFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanningItem
     */
    localTo?: string | null;
    /**
     * 
     * @type {PlanningItemType}
     * @memberof PlanningItem
     */
    type?: PlanningItemType;
    /**
     * 
     * @type {string}
     * @memberof PlanningItem
     */
    allocationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlanningItem
     */
    parkingName?: string | null;
    /**
     * 
     * @type {SpaceTypes}
     * @memberof PlanningItem
     */
    spaceType?: SpaceTypes;
    /**
     * 
     * @type {boolean}
     * @memberof PlanningItem
     */
    chargingPointRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlanningItem
     */
    prmSpaceRequired?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlanningItem
     */
    vehicleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlanningItem
     */
    vehicleRegistrationPlate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlanningItem
     */
    vehicleRegistrationPlateCountry?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PlanningItemType {
    Reservation = 'Reservation',
    ManualAllocation = 'ManualAllocation',
    Release = 'Release'
}

/**
 * 
 * @export
 * @interface PlanningResponse
 */
export interface PlanningResponse {
    /**
     * Dictionary containing a planning of allocations for each day requested
     * @type {{ [key: string]: PlanningDay; }}
     * @memberof PlanningResponse
     */
    planningByDays?: { [key: string]: PlanningDay; } | null;
}
/**
 * 
 * @export
 * @interface RefreshTokenRequest
 */
export interface RefreshTokenRequest {
    /**
     * Member\'s Refresh Token that allow refreshing the access token
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    refreshToken: string;
    /**
     * Member\'s Access Token to refresh
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    accessToken: string;
    /**
     * Member\'s Identity Token containing claims to compute parking groups (for SSO only)
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    identityToken?: string | null;
}
/**
 * 
 * @export
 * @interface Reservation
 */
export interface Reservation {
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    localFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    localTo?: string;
}
/**
 * 
 * @export
 * @interface ReservationDetails
 */
export interface ReservationDetails {
    /**
     * 
     * @type {string}
     * @memberof ReservationDetails
     */
    localFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationDetails
     */
    localTo?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationDetails
     */
    parkingId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationDetails
     */
    vehicleId?: string;
    /**
     * 
     * @type {SpaceTypes}
     * @memberof ReservationDetails
     */
    spaceType?: SpaceTypes;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationDetails
     */
    isForPrm?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationDetails
     */
    hasChargingPoint?: boolean;
}
/**
 * 
 * @export
 * @interface ReserveResponse
 */
export interface ReserveResponse {
    /**
     * The response title
     * @type {string}
     * @memberof ReserveResponse
     */
    title?: string | null;
    /**
     * The response message
     * @type {string}
     * @memberof ReserveResponse
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * Reset token
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    token: string;
    /**
     * Driver\'s new password
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    newPassword: string;
}
/**
 * 
 * @export
 * @interface ResetPasswordResponse
 */
export interface ResetPasswordResponse {
    /**
     * The response title
     * @type {string}
     * @memberof ResetPasswordResponse
     */
    title?: string | null;
    /**
     * The response message
     * @type {string}
     * @memberof ResetPasswordResponse
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface RuleState
 */
export interface RuleState {
    /**
     * True if the rule is satisfied, false otherwise
     * @type {boolean}
     * @memberof RuleState
     */
    success?: boolean | null;
    /**
     * A message explaining why the rule is in success or not
     * @type {string}
     * @memberof RuleState
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface SaveOptinRequest
 */
export interface SaveOptinRequest {
    /**
     * True if driver wants to receive marketing communications
     * @type {boolean}
     * @memberof SaveOptinRequest
     */
    marketingOptin: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SpaceAllocationType {
    None = 'None',
    Manual = 'Manual',
    Priority = 'Priority'
}

/**
 * 
 * @export
 * @interface SpaceReleaseResponse
 */
export interface SpaceReleaseResponse {
    /**
     * 
     * @type {string}
     * @memberof SpaceReleaseResponse
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpaceReleaseResponse
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SpaceTypes {
    None = 'None',
    Moto = 'Moto',
    Car = 'Car',
    Utility = 'Utility',
    Bike = 'Bike'
}

/**
 * 
 * @export
 * @interface SsoCodeValidationRequest
 */
export interface SsoCodeValidationRequest {
    /**
     * The authorization code to validate
     * @type {string}
     * @memberof SsoCodeValidationRequest
     */
    code: string;
    /**
     * The Issuer Identifier
     * @type {string}
     * @memberof SsoCodeValidationRequest
     */
    issuer?: string | null;
    /**
     * 
     * @type {SsoProvider}
     * @memberof SsoCodeValidationRequest
     */
    provider: SsoProvider;
    /**
     * 
     * @type {string}
     * @memberof SsoCodeValidationRequest
     */
    redirectUri: string;
    /**
     * 
     * @type {string}
     * @memberof SsoCodeValidationRequest
     */
    codeVerifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SsoCodeValidationRequest
     */
    nonce?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SsoProtocol {
    Oidc = 'Oidc',
    Cas = 'Cas',
    Saml = 'Saml'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum SsoProvider {
    ZenparkOidc = 'ZenparkOidc',
    Bpce = 'Bpce',
    Auxiliaire = 'Auxiliaire',
    BpceCera = 'BpceCera',
    Edf = 'Edf',
    Primonial = 'Primonial'
}

/**
 * 
 * @export
 * @interface UpdateDriverResponse
 */
export interface UpdateDriverResponse {
    /**
     * The response title
     * @type {string}
     * @memberof UpdateDriverResponse
     */
    title?: string | null;
    /**
     * The response message
     * @type {string}
     * @memberof UpdateDriverResponse
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface UpdatePasswordRequest
 */
export interface UpdatePasswordRequest {
    /**
     * Driver\'s current password
     * @type {string}
     * @memberof UpdatePasswordRequest
     */
    currentPassword: string;
    /**
     * Driver\'s new password
     * @type {string}
     * @memberof UpdatePasswordRequest
     */
    newPassword: string;
    /**
     * Confirm password
     * @type {string}
     * @memberof UpdatePasswordRequest
     */
    confirmPassword: string;
}
/**
 * 
 * @export
 * @interface UpdatePasswordResponse
 */
export interface UpdatePasswordResponse {
    /**
     * The response title
     * @type {string}
     * @memberof UpdatePasswordResponse
     */
    title?: string | null;
    /**
     * The response message
     * @type {string}
     * @memberof UpdatePasswordResponse
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateReservationResponse
 */
export interface UpdateReservationResponse {
    /**
     * The response title
     * @type {string}
     * @memberof UpdateReservationResponse
     */
    title?: string | null;
    /**
     * The response message
     * @type {string}
     * @memberof UpdateReservationResponse
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface UpdatedDriver
 */
export interface UpdatedDriver {
    /**
     * 
     * @type {string}
     * @memberof UpdatedDriver
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatedDriver
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatedDriver
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatedDriver
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatedDriver
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatedDriver
     */
    isPrm?: boolean;
    /**
     * 
     * @type {CreationOrigin}
     * @memberof UpdatedDriver
     */
    creationOrigin?: CreationOrigin;
}
/**
 * 
 * @export
 * @interface UpdatedReservation
 */
export interface UpdatedReservation {
    /**
     * 
     * @type {string}
     * @memberof UpdatedReservation
     */
    id?: string;
    /**
     * 
     * @type {SpaceTypes}
     * @memberof UpdatedReservation
     */
    spaceType?: SpaceTypes;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatedReservation
     */
    chargingPointRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatedReservation
     */
    prmSpaceRequired?: boolean;
    /**
     * Parking identifier
     * @type {string}
     * @memberof UpdatedReservation
     */
    parkingId?: string;
    /**
     * Vehicle identifier
     * @type {string}
     * @memberof UpdatedReservation
     */
    vehicleId?: string;
}
/**
 * 
 * @export
 * @interface UpdatedVehicle
 */
export interface UpdatedVehicle {
    /**
     * Car name
     * @type {string}
     * @memberof UpdatedVehicle
     */
    name: string;
    /**
     * 
     * @type {SpaceTypes}
     * @memberof UpdatedVehicle
     */
    type: SpaceTypes;
    /**
     * Is eletrical vehicle
     * @type {boolean}
     * @memberof UpdatedVehicle
     */
    isEv: boolean;
    /**
     * The vehicle registration plate
     * @type {string}
     * @memberof UpdatedVehicle
     */
    registrationPlate?: string | null;
    /**
     * The vehicle registration plate country
     * @type {string}
     * @memberof UpdatedVehicle
     */
    registrationPlateCountry?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UserStatus {
    Invited = 'Invited',
    Active = 'Active',
    Suspended = 'Suspended',
    Closed = 'Closed'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserType {
    Driver = 'Driver',
    Manager = 'Manager',
    Admin = 'Admin'
}

/**
 * 
 * @export
 * @interface Vehicle
 */
export interface Vehicle {
    /**
     * The vehicle unique identifier
     * @type {string}
     * @memberof Vehicle
     */
    vehicleId?: string;
    /**
     * The vehicle name
     * @type {string}
     * @memberof Vehicle
     */
    name?: string | null;
    /**
     * 
     * @type {SpaceTypes}
     * @memberof Vehicle
     */
    type?: SpaceTypes;
    /**
     * Is eletrical vehicle
     * @type {boolean}
     * @memberof Vehicle
     */
    isEv?: boolean;
    /**
     * The vehicle registration plate
     * @type {string}
     * @memberof Vehicle
     */
    registrationPlate?: string | null;
    /**
     * The vehicle registration plate country
     * @type {string}
     * @memberof Vehicle
     */
    registrationPlateCountry?: string | null;
    /**
     * The vehicle creation datetime
     * @type {string}
     * @memberof Vehicle
     */
    creationDateTime?: string;
}
/**
 * 
 * @export
 * @interface VehicleResponse
 */
export interface VehicleResponse {
    /**
     * The response message
     * @type {string}
     * @memberof VehicleResponse
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Way {
    CarEntry = 'CarEntry',
    CarExit = 'CarExit',
    Pedestrian = 'Pedestrian'
}


/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a list of drivers by email, first name or last name
         * @param {string} filter The filter used to find a user by email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminGetDriversFilterGet: async (filter: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('v1AdminGetDriversFilterGet', 'filter', filter)
            const localVarPath = `/v1/Admin/get-drivers/{filter}`
                .replace(`{${"filter"}}`, encodeURIComponent(String(filter)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticate as a user
         * @param {string} [body] Email of the user you want to authenticate as
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminLoginAsUserPost: async (body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/login-as-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a list of drivers by email, first name or last name
         * @param {string} filter The filter used to find a user by email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminGetDriversFilterGet(filter: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DriverLight>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminGetDriversFilterGet(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authenticate as a user
         * @param {string} [body] Email of the user you want to authenticate as
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminLoginAsUserPost(body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminLoginAsUserPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a list of drivers by email, first name or last name
         * @param {string} filter The filter used to find a user by email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminGetDriversFilterGet(filter: string, options?: any): AxiosPromise<Array<DriverLight>> {
            return localVarFp.v1AdminGetDriversFilterGet(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticate as a user
         * @param {string} [body] Email of the user you want to authenticate as
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminLoginAsUserPost(body?: string, options?: any): AxiosPromise<AuthTicket> {
            return localVarFp.v1AdminLoginAsUserPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary Get a list of drivers by email, first name or last name
     * @param {string} filter The filter used to find a user by email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public v1AdminGetDriversFilterGet(filter: string, options?: any) {
        return AdminApiFp(this.configuration).v1AdminGetDriversFilterGet(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authenticate as a user
     * @param {string} [body] Email of the user you want to authenticate as
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public v1AdminLoginAsUserPost(body?: string, options?: any) {
        return AdminApiFp(this.configuration).v1AdminLoginAsUserPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate account with the password
         * @param {CreateAccountRequest} [createAccountRequest] Create account request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AuthenticationActivateAccountPost: async (createAccountRequest?: CreateAccountRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Authentication/activate-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if the create account token is valid
         * @param {string} [token] Create account token
         * @param {string} [email] Account email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AuthenticationCanActivateAccountGet: async (token?: string, email?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Authentication/can-activate-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticate a user
         * @param {LoginRequest} [loginRequest] Authentication information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AuthenticationLoginPost: async (loginRequest?: LoginRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh the access token
         * @param {RefreshTokenRequest} [refreshTokenRequest] Token used to refresh the access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AuthenticationRefreshPost: async (refreshTokenRequest?: RefreshTokenRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Authentication/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend a new token by email
         * @param {string} [body] User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AuthenticationResendActivationMailPost: async (body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Authentication/resend-activation-mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate account with the password
         * @param {CreateAccountRequest} [createAccountRequest] Create account request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AuthenticationActivateAccountPost(createAccountRequest?: CreateAccountRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AuthenticationActivateAccountPost(createAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if the create account token is valid
         * @param {string} [token] Create account token
         * @param {string} [email] Account email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AuthenticationCanActivateAccountGet(token?: string, email?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AuthenticationCanActivateAccountGet(token, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authenticate a user
         * @param {LoginRequest} [loginRequest] Authentication information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AuthenticationLoginPost(loginRequest?: LoginRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AuthenticationLoginPost(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refresh the access token
         * @param {RefreshTokenRequest} [refreshTokenRequest] Token used to refresh the access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AuthenticationRefreshPost(refreshTokenRequest?: RefreshTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AuthenticationRefreshPost(refreshTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend a new token by email
         * @param {string} [body] User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AuthenticationResendActivationMailPost(body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AuthenticationResendActivationMailPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate account with the password
         * @param {CreateAccountRequest} [createAccountRequest] Create account request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AuthenticationActivateAccountPost(createAccountRequest?: CreateAccountRequest, options?: any): AxiosPromise<AuthTicket> {
            return localVarFp.v1AuthenticationActivateAccountPost(createAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if the create account token is valid
         * @param {string} [token] Create account token
         * @param {string} [email] Account email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AuthenticationCanActivateAccountGet(token?: string, email?: string, options?: any): AxiosPromise<object> {
            return localVarFp.v1AuthenticationCanActivateAccountGet(token, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticate a user
         * @param {LoginRequest} [loginRequest] Authentication information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AuthenticationLoginPost(loginRequest?: LoginRequest, options?: any): AxiosPromise<AuthTicket> {
            return localVarFp.v1AuthenticationLoginPost(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh the access token
         * @param {RefreshTokenRequest} [refreshTokenRequest] Token used to refresh the access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AuthenticationRefreshPost(refreshTokenRequest?: RefreshTokenRequest, options?: any): AxiosPromise<AuthTicket> {
            return localVarFp.v1AuthenticationRefreshPost(refreshTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend a new token by email
         * @param {string} [body] User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AuthenticationResendActivationMailPost(body?: string, options?: any): AxiosPromise<object> {
            return localVarFp.v1AuthenticationResendActivationMailPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Activate account with the password
     * @param {CreateAccountRequest} [createAccountRequest] Create account request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public v1AuthenticationActivateAccountPost(createAccountRequest?: CreateAccountRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).v1AuthenticationActivateAccountPost(createAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if the create account token is valid
     * @param {string} [token] Create account token
     * @param {string} [email] Account email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public v1AuthenticationCanActivateAccountGet(token?: string, email?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).v1AuthenticationCanActivateAccountGet(token, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authenticate a user
     * @param {LoginRequest} [loginRequest] Authentication information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public v1AuthenticationLoginPost(loginRequest?: LoginRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).v1AuthenticationLoginPost(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh the access token
     * @param {RefreshTokenRequest} [refreshTokenRequest] Token used to refresh the access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public v1AuthenticationRefreshPost(refreshTokenRequest?: RefreshTokenRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).v1AuthenticationRefreshPost(refreshTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend a new token by email
     * @param {string} [body] User email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public v1AuthenticationResendActivationMailPost(body?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).v1AuthenticationResendActivationMailPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesCompanyIdAlertMessageGet: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('v1CompaniesCompanyIdAlertMessageGet', 'companyId', companyId)
            const localVarPath = `/v1/Companies/{companyId}/alert-message`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns company configuration
         * @param {string} companyUrn The urn of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesCompanyUrnConfigGet: async (companyUrn: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyUrn' is not null or undefined
            assertParamExists('v1CompaniesCompanyUrnConfigGet', 'companyUrn', companyUrn)
            const localVarPath = `/v1/Companies/{companyUrn}/config`
                .replace(`{${"companyUrn"}}`, encodeURIComponent(String(companyUrn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesSupportedVehiclesTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Companies/supported-vehicles-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CompaniesCompanyIdAlertMessageGet(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyAlertMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CompaniesCompanyIdAlertMessageGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns company configuration
         * @param {string} companyUrn The urn of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CompaniesCompanyUrnConfigGet(companyUrn: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CompaniesCompanyUrnConfigGet(companyUrn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CompaniesSupportedVehiclesTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpaceTypes>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CompaniesSupportedVehiclesTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesCompanyIdAlertMessageGet(companyId: string, options?: any): AxiosPromise<CompanyAlertMessage> {
            return localVarFp.v1CompaniesCompanyIdAlertMessageGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns company configuration
         * @param {string} companyUrn The urn of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesCompanyUrnConfigGet(companyUrn: string, options?: any): AxiosPromise<CompanyConfig> {
            return localVarFp.v1CompaniesCompanyUrnConfigGet(companyUrn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesSupportedVehiclesTypesGet(options?: any): AxiosPromise<Array<SpaceTypes>> {
            return localVarFp.v1CompaniesSupportedVehiclesTypesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public v1CompaniesCompanyIdAlertMessageGet(companyId: string, options?: any) {
        return CompaniesApiFp(this.configuration).v1CompaniesCompanyIdAlertMessageGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns company configuration
     * @param {string} companyUrn The urn of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public v1CompaniesCompanyUrnConfigGet(companyUrn: string, options?: any) {
        return CompaniesApiFp(this.configuration).v1CompaniesCompanyUrnConfigGet(companyUrn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public v1CompaniesSupportedVehiclesTypesGet(options?: any) {
        return CompaniesApiFp(this.configuration).v1CompaniesSupportedVehiclesTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the application configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConfigurationGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns the application configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConfigurationGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ConfigurationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns the application configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConfigurationGet(options?: any): AxiosPromise<ConfigurationModel> {
            return localVarFp.v1ConfigurationGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary Returns the application configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public v1ConfigurationGet(options?: any) {
        return ConfigurationApiFp(this.configuration).v1ConfigurationGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DriversApi - axios parameter creator
 * @export
 */
export const DriversApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a planning of allocations in a parking between dates
         * @param {string} firstDay First day of the planning to get, date with timezone
         * @param {string} lastDay Last day of the planning to get, date with timezone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DriversAllocationsGet: async (firstDay: string, lastDay: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'firstDay' is not null or undefined
            assertParamExists('v1DriversAllocationsGet', 'firstDay', firstDay)
            // verify required parameter 'lastDay' is not null or undefined
            assertParamExists('v1DriversAllocationsGet', 'lastDay', lastDay)
            const localVarPath = `/v1/Drivers/allocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (firstDay !== undefined) {
                localVarQueryParameter['firstDay'] = (firstDay as any instanceof Date) ?
                    (firstDay as any).toISOString() :
                    firstDay;
            }

            if (lastDay !== undefined) {
                localVarQueryParameter['lastDay'] = (lastDay as any instanceof Date) ?
                    (lastDay as any).toISOString() :
                    lastDay;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change driver culture, will update culture for all interfaces and communications
         * @param {ChangeCultureRequest} [changeCultureRequest] change culture request, it contains the new culture
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DriversChangeCulturePatch: async (changeCultureRequest?: ChangeCultureRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Drivers/change-culture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeCultureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an email to reset password
         * @param {ForgottenPasswordRequest} [forgottenPasswordRequest] forgotten password request, it contains the driver email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DriversForgottenPasswordPatch: async (forgottenPasswordRequest?: ForgottenPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Drivers/forgotten-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgottenPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns driver personal information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DriversInfoGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Drivers/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a driver registration request
         * @param {DriverRegistrationRequest} [driverRegistrationRequest] the driver registration request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DriversRegisterPost: async (driverRegistrationRequest?: DriverRegistrationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Drivers/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(driverRegistrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update driver password with a reset link
         * @param {ResetPasswordRequest} [resetPasswordRequest] reset password request, it contains the reset token and the new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DriversResetPasswordPatch: async (resetPasswordRequest?: ResetPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Drivers/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update driver\'s informations
         * @param {UpdatedDriver} [updatedDriver] update driver request, it contains all the driver\&#39;s information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DriversUpdateDriverPatch: async (updatedDriver?: UpdatedDriver, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Drivers/update-driver`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatedDriver, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update driver password
         * @param {UpdatePasswordRequest} [updatePasswordRequest] update password request, it contains the old and the new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DriversUpdatePasswordPatch: async (updatePasswordRequest?: UpdatePasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Drivers/update-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DriversApi - functional programming interface
 * @export
 */
export const DriversApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DriversApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a planning of allocations in a parking between dates
         * @param {string} firstDay First day of the planning to get, date with timezone
         * @param {string} lastDay Last day of the planning to get, date with timezone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DriversAllocationsGet(firstDay: string, lastDay: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanningResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DriversAllocationsGet(firstDay, lastDay, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change driver culture, will update culture for all interfaces and communications
         * @param {ChangeCultureRequest} [changeCultureRequest] change culture request, it contains the new culture
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DriversChangeCulturePatch(changeCultureRequest?: ChangeCultureRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeCultureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DriversChangeCulturePatch(changeCultureRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send an email to reset password
         * @param {ForgottenPasswordRequest} [forgottenPasswordRequest] forgotten password request, it contains the driver email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DriversForgottenPasswordPatch(forgottenPasswordRequest?: ForgottenPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForgottenPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DriversForgottenPasswordPatch(forgottenPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns driver personal information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DriversInfoGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DriversInfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a driver registration request
         * @param {DriverRegistrationRequest} [driverRegistrationRequest] the driver registration request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DriversRegisterPost(driverRegistrationRequest?: DriverRegistrationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReserveResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DriversRegisterPost(driverRegistrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update driver password with a reset link
         * @param {ResetPasswordRequest} [resetPasswordRequest] reset password request, it contains the reset token and the new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DriversResetPasswordPatch(resetPasswordRequest?: ResetPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DriversResetPasswordPatch(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update driver\'s informations
         * @param {UpdatedDriver} [updatedDriver] update driver request, it contains all the driver\&#39;s information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DriversUpdateDriverPatch(updatedDriver?: UpdatedDriver, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDriverResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DriversUpdateDriverPatch(updatedDriver, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update driver password
         * @param {UpdatePasswordRequest} [updatePasswordRequest] update password request, it contains the old and the new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DriversUpdatePasswordPatch(updatePasswordRequest?: UpdatePasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DriversUpdatePasswordPatch(updatePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DriversApi - factory interface
 * @export
 */
export const DriversApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DriversApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a planning of allocations in a parking between dates
         * @param {string} firstDay First day of the planning to get, date with timezone
         * @param {string} lastDay Last day of the planning to get, date with timezone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DriversAllocationsGet(firstDay: string, lastDay: string, options?: any): AxiosPromise<PlanningResponse> {
            return localVarFp.v1DriversAllocationsGet(firstDay, lastDay, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change driver culture, will update culture for all interfaces and communications
         * @param {ChangeCultureRequest} [changeCultureRequest] change culture request, it contains the new culture
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DriversChangeCulturePatch(changeCultureRequest?: ChangeCultureRequest, options?: any): AxiosPromise<ChangeCultureResponse> {
            return localVarFp.v1DriversChangeCulturePatch(changeCultureRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an email to reset password
         * @param {ForgottenPasswordRequest} [forgottenPasswordRequest] forgotten password request, it contains the driver email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DriversForgottenPasswordPatch(forgottenPasswordRequest?: ForgottenPasswordRequest, options?: any): AxiosPromise<ForgottenPasswordResponse> {
            return localVarFp.v1DriversForgottenPasswordPatch(forgottenPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns driver personal information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DriversInfoGet(options?: any): AxiosPromise<DriverInfo> {
            return localVarFp.v1DriversInfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a driver registration request
         * @param {DriverRegistrationRequest} [driverRegistrationRequest] the driver registration request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DriversRegisterPost(driverRegistrationRequest?: DriverRegistrationRequest, options?: any): AxiosPromise<ReserveResponse> {
            return localVarFp.v1DriversRegisterPost(driverRegistrationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update driver password with a reset link
         * @param {ResetPasswordRequest} [resetPasswordRequest] reset password request, it contains the reset token and the new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DriversResetPasswordPatch(resetPasswordRequest?: ResetPasswordRequest, options?: any): AxiosPromise<ResetPasswordResponse> {
            return localVarFp.v1DriversResetPasswordPatch(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update driver\'s informations
         * @param {UpdatedDriver} [updatedDriver] update driver request, it contains all the driver\&#39;s information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DriversUpdateDriverPatch(updatedDriver?: UpdatedDriver, options?: any): AxiosPromise<UpdateDriverResponse> {
            return localVarFp.v1DriversUpdateDriverPatch(updatedDriver, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update driver password
         * @param {UpdatePasswordRequest} [updatePasswordRequest] update password request, it contains the old and the new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DriversUpdatePasswordPatch(updatePasswordRequest?: UpdatePasswordRequest, options?: any): AxiosPromise<UpdatePasswordResponse> {
            return localVarFp.v1DriversUpdatePasswordPatch(updatePasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DriversApi - object-oriented interface
 * @export
 * @class DriversApi
 * @extends {BaseAPI}
 */
export class DriversApi extends BaseAPI {
    /**
     * 
     * @summary Get a planning of allocations in a parking between dates
     * @param {string} firstDay First day of the planning to get, date with timezone
     * @param {string} lastDay Last day of the planning to get, date with timezone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriversApi
     */
    public v1DriversAllocationsGet(firstDay: string, lastDay: string, options?: any) {
        return DriversApiFp(this.configuration).v1DriversAllocationsGet(firstDay, lastDay, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change driver culture, will update culture for all interfaces and communications
     * @param {ChangeCultureRequest} [changeCultureRequest] change culture request, it contains the new culture
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriversApi
     */
    public v1DriversChangeCulturePatch(changeCultureRequest?: ChangeCultureRequest, options?: any) {
        return DriversApiFp(this.configuration).v1DriversChangeCulturePatch(changeCultureRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an email to reset password
     * @param {ForgottenPasswordRequest} [forgottenPasswordRequest] forgotten password request, it contains the driver email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriversApi
     */
    public v1DriversForgottenPasswordPatch(forgottenPasswordRequest?: ForgottenPasswordRequest, options?: any) {
        return DriversApiFp(this.configuration).v1DriversForgottenPasswordPatch(forgottenPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns driver personal information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriversApi
     */
    public v1DriversInfoGet(options?: any) {
        return DriversApiFp(this.configuration).v1DriversInfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a driver registration request
     * @param {DriverRegistrationRequest} [driverRegistrationRequest] the driver registration request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriversApi
     */
    public v1DriversRegisterPost(driverRegistrationRequest?: DriverRegistrationRequest, options?: any) {
        return DriversApiFp(this.configuration).v1DriversRegisterPost(driverRegistrationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update driver password with a reset link
     * @param {ResetPasswordRequest} [resetPasswordRequest] reset password request, it contains the reset token and the new password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriversApi
     */
    public v1DriversResetPasswordPatch(resetPasswordRequest?: ResetPasswordRequest, options?: any) {
        return DriversApiFp(this.configuration).v1DriversResetPasswordPatch(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update driver\'s informations
     * @param {UpdatedDriver} [updatedDriver] update driver request, it contains all the driver\&#39;s information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriversApi
     */
    public v1DriversUpdateDriverPatch(updatedDriver?: UpdatedDriver, options?: any) {
        return DriversApiFp(this.configuration).v1DriversUpdateDriverPatch(updatedDriver, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update driver password
     * @param {UpdatePasswordRequest} [updatePasswordRequest] update password request, it contains the old and the new password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriversApi
     */
    public v1DriversUpdatePasswordPatch(updatePasswordRequest?: UpdatePasswordRequest, options?: any) {
        return DriversApiFp(this.configuration).v1DriversUpdatePasswordPatch(updatePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketingOptinApi - axios parameter creator
 * @export
 */
export const MarketingOptinApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Save marketing optin for driver
         * @param {SaveOptinRequest} [saveOptinRequest] Save optin request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MarketingOptinSaveOptinPost: async (saveOptinRequest?: SaveOptinRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/MarketingOptin/save-optin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveOptinRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gives the information if driver should give marketing optin decision
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MarketingOptinShouldOptinGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/MarketingOptin/should-optin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketingOptinApi - functional programming interface
 * @export
 */
export const MarketingOptinApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketingOptinApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Save marketing optin for driver
         * @param {SaveOptinRequest} [saveOptinRequest] Save optin request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MarketingOptinSaveOptinPost(saveOptinRequest?: SaveOptinRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MarketingOptinSaveOptinPost(saveOptinRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gives the information if driver should give marketing optin decision
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MarketingOptinShouldOptinGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MarketingOptinShouldOptinGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketingOptinApi - factory interface
 * @export
 */
export const MarketingOptinApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketingOptinApiFp(configuration)
    return {
        /**
         * 
         * @summary Save marketing optin for driver
         * @param {SaveOptinRequest} [saveOptinRequest] Save optin request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MarketingOptinSaveOptinPost(saveOptinRequest?: SaveOptinRequest, options?: any): AxiosPromise<object> {
            return localVarFp.v1MarketingOptinSaveOptinPost(saveOptinRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gives the information if driver should give marketing optin decision
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MarketingOptinShouldOptinGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.v1MarketingOptinShouldOptinGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketingOptinApi - object-oriented interface
 * @export
 * @class MarketingOptinApi
 * @extends {BaseAPI}
 */
export class MarketingOptinApi extends BaseAPI {
    /**
     * 
     * @summary Save marketing optin for driver
     * @param {SaveOptinRequest} [saveOptinRequest] Save optin request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingOptinApi
     */
    public v1MarketingOptinSaveOptinPost(saveOptinRequest?: SaveOptinRequest, options?: any) {
        return MarketingOptinApiFp(this.configuration).v1MarketingOptinSaveOptinPost(saveOptinRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gives the information if driver should give marketing optin decision
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingOptinApi
     */
    public v1MarketingOptinShouldOptinGet(options?: any) {
        return MarketingOptinApiFp(this.configuration).v1MarketingOptinShouldOptinGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PacoDriverFrontOfficeApiApi - axios parameter creator
 * @export
 */
export const PacoDriverFrontOfficeApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionHead: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PacoDriverFrontOfficeApiApi - functional programming interface
 * @export
 */
export const PacoDriverFrontOfficeApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PacoDriverFrontOfficeApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.versionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionHead(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.versionHead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PacoDriverFrontOfficeApiApi - factory interface
 * @export
 */
export const PacoDriverFrontOfficeApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PacoDriverFrontOfficeApiApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionGet(options?: any): AxiosPromise<string> {
            return localVarFp.versionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionHead(options?: any): AxiosPromise<string> {
            return localVarFp.versionHead(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PacoDriverFrontOfficeApiApi - object-oriented interface
 * @export
 * @class PacoDriverFrontOfficeApiApi
 * @extends {BaseAPI}
 */
export class PacoDriverFrontOfficeApiApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacoDriverFrontOfficeApiApi
     */
    public versionGet(options?: any) {
        return PacoDriverFrontOfficeApiApiFp(this.configuration).versionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacoDriverFrontOfficeApiApi
     */
    public versionHead(options?: any) {
        return PacoDriverFrontOfficeApiApiFp(this.configuration).versionHead(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ParkingsApi - axios parameter creator
 * @export
 */
export const ParkingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Fetch door opening possibilities at current date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ParkingsGetAccessGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Parkings/get-access`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch door opening possibilities at current date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ParkingsGetUpcomingAccessGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Parkings/get-upcoming-access`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Opens a parking door identified by parking and driver IDs
         * @param {string} parkingId The parking you want to open
         * @param {string} body Car entry, car exit or pedestrian
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ParkingsParkingIdAccessPost: async (parkingId: string, body: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'parkingId' is not null or undefined
            assertParamExists('v1ParkingsParkingIdAccessPost', 'parkingId', parkingId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('v1ParkingsParkingIdAccessPost', 'body', body)
            const localVarPath = `/v1/Parkings/{parkingId}/access`
                .replace(`{${"parkingId"}}`, encodeURIComponent(String(parkingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if each rule is correctly followed
         * @param {string} parkingId The parking to check rules in
         * @param {string} [localFrom] Reservation start date with timezone
         * @param {string} [localTo] Reservation end date with timezone
         * @param {string} [allocationId] Id of the existing reservation if it already exists (when updating one)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ParkingsParkingIdEvaluateRulesGet: async (parkingId: string, localFrom?: string, localTo?: string, allocationId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'parkingId' is not null or undefined
            assertParamExists('v1ParkingsParkingIdEvaluateRulesGet', 'parkingId', parkingId)
            const localVarPath = `/v1/Parkings/{parkingId}/evaluate-rules`
                .replace(`{${"parkingId"}}`, encodeURIComponent(String(parkingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (localFrom !== undefined) {
                localVarQueryParameter['localFrom'] = (localFrom as any instanceof Date) ?
                    (localFrom as any).toISOString() :
                    localFrom;
            }

            if (localTo !== undefined) {
                localVarQueryParameter['localTo'] = (localTo as any instanceof Date) ?
                    (localTo as any).toISOString() :
                    localTo;
            }

            if (allocationId !== undefined) {
                localVarQueryParameter['allocationId'] = allocationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParkingsApi - functional programming interface
 * @export
 */
export const ParkingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParkingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Fetch door opening possibilities at current date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ParkingsGetAccessGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Access>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ParkingsGetAccessGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch door opening possibilities at current date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ParkingsGetUpcomingAccessGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ParkingsGetUpcomingAccessGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Opens a parking door identified by parking and driver IDs
         * @param {string} parkingId The parking you want to open
         * @param {string} body Car entry, car exit or pedestrian
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ParkingsParkingIdAccessPost(parkingId: string, body: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ParkingsParkingIdAccessPost(parkingId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if each rule is correctly followed
         * @param {string} parkingId The parking to check rules in
         * @param {string} [localFrom] Reservation start date with timezone
         * @param {string} [localTo] Reservation end date with timezone
         * @param {string} [allocationId] Id of the existing reservation if it already exists (when updating one)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ParkingsParkingIdEvaluateRulesGet(parkingId: string, localFrom?: string, localTo?: string, allocationId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RuleState>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ParkingsParkingIdEvaluateRulesGet(parkingId, localFrom, localTo, allocationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParkingsApi - factory interface
 * @export
 */
export const ParkingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParkingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Fetch door opening possibilities at current date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ParkingsGetAccessGet(options?: any): AxiosPromise<Access> {
            return localVarFp.v1ParkingsGetAccessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch door opening possibilities at current date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ParkingsGetUpcomingAccessGet(options?: any): AxiosPromise<AccessResult> {
            return localVarFp.v1ParkingsGetUpcomingAccessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Opens a parking door identified by parking and driver IDs
         * @param {string} parkingId The parking you want to open
         * @param {string} body Car entry, car exit or pedestrian
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ParkingsParkingIdAccessPost(parkingId: string, body: string, options?: any): AxiosPromise<AccessResponse> {
            return localVarFp.v1ParkingsParkingIdAccessPost(parkingId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if each rule is correctly followed
         * @param {string} parkingId The parking to check rules in
         * @param {string} [localFrom] Reservation start date with timezone
         * @param {string} [localTo] Reservation end date with timezone
         * @param {string} [allocationId] Id of the existing reservation if it already exists (when updating one)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ParkingsParkingIdEvaluateRulesGet(parkingId: string, localFrom?: string, localTo?: string, allocationId?: string, options?: any): AxiosPromise<Array<RuleState>> {
            return localVarFp.v1ParkingsParkingIdEvaluateRulesGet(parkingId, localFrom, localTo, allocationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParkingsApi - object-oriented interface
 * @export
 * @class ParkingsApi
 * @extends {BaseAPI}
 */
export class ParkingsApi extends BaseAPI {
    /**
     * 
     * @summary Fetch door opening possibilities at current date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingsApi
     */
    public v1ParkingsGetAccessGet(options?: any) {
        return ParkingsApiFp(this.configuration).v1ParkingsGetAccessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch door opening possibilities at current date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingsApi
     */
    public v1ParkingsGetUpcomingAccessGet(options?: any) {
        return ParkingsApiFp(this.configuration).v1ParkingsGetUpcomingAccessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Opens a parking door identified by parking and driver IDs
     * @param {string} parkingId The parking you want to open
     * @param {string} body Car entry, car exit or pedestrian
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingsApi
     */
    public v1ParkingsParkingIdAccessPost(parkingId: string, body: string, options?: any) {
        return ParkingsApiFp(this.configuration).v1ParkingsParkingIdAccessPost(parkingId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if each rule is correctly followed
     * @param {string} parkingId The parking to check rules in
     * @param {string} [localFrom] Reservation start date with timezone
     * @param {string} [localTo] Reservation end date with timezone
     * @param {string} [allocationId] Id of the existing reservation if it already exists (when updating one)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingsApi
     */
    public v1ParkingsParkingIdEvaluateRulesGet(parkingId: string, localFrom?: string, localTo?: string, allocationId?: string, options?: any) {
        return ParkingsApiFp(this.configuration).v1ParkingsParkingIdEvaluateRulesGet(parkingId, localFrom, localTo, allocationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReservationsApi - axios parameter creator
 * @export
 */
export const ReservationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get newest reservation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationsNewestGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Reservations/newest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a reservation
         * @param {UpdatedReservation} [updatedReservation] Parameters of the reservation to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationsPatch: async (updatedReservation?: UpdatedReservation, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Reservations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatedReservation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new reservation
         * @param {NewReservation} [newReservation] Search parameters for a reservation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationsPost: async (newReservation?: NewReservation, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Reservations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newReservation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel a reservation
         * @param {string} reservationId The reservation to cancel identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationsReservationIdDelete: async (reservationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('v1ReservationsReservationIdDelete', 'reservationId', reservationId)
            const localVarPath = `/v1/Reservations/{reservationId}`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get reservation details
         * @param {string} reservationId The reservation you want information about
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationsReservationIdGet: async (reservationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('v1ReservationsReservationIdGet', 'reservationId', reservationId)
            const localVarPath = `/v1/Reservations/{reservationId}`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReservationsApi - functional programming interface
 * @export
 */
export const ReservationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReservationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get newest reservation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationsNewestGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationsNewestGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a reservation
         * @param {UpdatedReservation} [updatedReservation] Parameters of the reservation to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationsPatch(updatedReservation?: UpdatedReservation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateReservationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationsPatch(updatedReservation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new reservation
         * @param {NewReservation} [newReservation] Search parameters for a reservation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationsPost(newReservation?: NewReservation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReserveResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationsPost(newReservation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel a reservation
         * @param {string} reservationId The reservation to cancel identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationsReservationIdDelete(reservationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelReservationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationsReservationIdDelete(reservationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get reservation details
         * @param {string} reservationId The reservation you want information about
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationsReservationIdGet(reservationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationsReservationIdGet(reservationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReservationsApi - factory interface
 * @export
 */
export const ReservationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReservationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get newest reservation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationsNewestGet(options?: any): AxiosPromise<Reservation> {
            return localVarFp.v1ReservationsNewestGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a reservation
         * @param {UpdatedReservation} [updatedReservation] Parameters of the reservation to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationsPatch(updatedReservation?: UpdatedReservation, options?: any): AxiosPromise<UpdateReservationResponse> {
            return localVarFp.v1ReservationsPatch(updatedReservation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new reservation
         * @param {NewReservation} [newReservation] Search parameters for a reservation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationsPost(newReservation?: NewReservation, options?: any): AxiosPromise<ReserveResponse> {
            return localVarFp.v1ReservationsPost(newReservation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel a reservation
         * @param {string} reservationId The reservation to cancel identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationsReservationIdDelete(reservationId: string, options?: any): AxiosPromise<CancelReservationResponse> {
            return localVarFp.v1ReservationsReservationIdDelete(reservationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get reservation details
         * @param {string} reservationId The reservation you want information about
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationsReservationIdGet(reservationId: string, options?: any): AxiosPromise<ReservationDetails> {
            return localVarFp.v1ReservationsReservationIdGet(reservationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReservationsApi - object-oriented interface
 * @export
 * @class ReservationsApi
 * @extends {BaseAPI}
 */
export class ReservationsApi extends BaseAPI {
    /**
     * 
     * @summary Get newest reservation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationsApi
     */
    public v1ReservationsNewestGet(options?: any) {
        return ReservationsApiFp(this.configuration).v1ReservationsNewestGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a reservation
     * @param {UpdatedReservation} [updatedReservation] Parameters of the reservation to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationsApi
     */
    public v1ReservationsPatch(updatedReservation?: UpdatedReservation, options?: any) {
        return ReservationsApiFp(this.configuration).v1ReservationsPatch(updatedReservation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new reservation
     * @param {NewReservation} [newReservation] Search parameters for a reservation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationsApi
     */
    public v1ReservationsPost(newReservation?: NewReservation, options?: any) {
        return ReservationsApiFp(this.configuration).v1ReservationsPost(newReservation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel a reservation
     * @param {string} reservationId The reservation to cancel identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationsApi
     */
    public v1ReservationsReservationIdDelete(reservationId: string, options?: any) {
        return ReservationsApiFp(this.configuration).v1ReservationsReservationIdDelete(reservationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get reservation details
     * @param {string} reservationId The reservation you want information about
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationsApi
     */
    public v1ReservationsReservationIdGet(reservationId: string, options?: any) {
        return ReservationsApiFp(this.configuration).v1ReservationsReservationIdGet(reservationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpaceReleasesApi - axios parameter creator
 * @export
 */
export const SpaceReleasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Release the manually allocated space at wished date
         * @param {string} [manualSpaceAllocationId] The identifier of the manual space allocation which should be released
         * @param {string} [releaseDate] The release date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SpaceReleasesSpaceReleasesNewPost: async (manualSpaceAllocationId?: string, releaseDate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/SpaceReleases/space-releases-new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manualSpaceAllocationId !== undefined) {
                localVarQueryParameter['manualSpaceAllocationId'] = manualSpaceAllocationId;
            }

            if (releaseDate !== undefined) {
                localVarQueryParameter['releaseDate'] = (releaseDate as any instanceof Date) ?
                    (releaseDate as any).toISOString() :
                    releaseDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpaceReleasesApi - functional programming interface
 * @export
 */
export const SpaceReleasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpaceReleasesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Release the manually allocated space at wished date
         * @param {string} [manualSpaceAllocationId] The identifier of the manual space allocation which should be released
         * @param {string} [releaseDate] The release date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SpaceReleasesSpaceReleasesNewPost(manualSpaceAllocationId?: string, releaseDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpaceReleaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SpaceReleasesSpaceReleasesNewPost(manualSpaceAllocationId, releaseDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpaceReleasesApi - factory interface
 * @export
 */
export const SpaceReleasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpaceReleasesApiFp(configuration)
    return {
        /**
         * 
         * @summary Release the manually allocated space at wished date
         * @param {string} [manualSpaceAllocationId] The identifier of the manual space allocation which should be released
         * @param {string} [releaseDate] The release date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SpaceReleasesSpaceReleasesNewPost(manualSpaceAllocationId?: string, releaseDate?: string, options?: any): AxiosPromise<SpaceReleaseResponse> {
            return localVarFp.v1SpaceReleasesSpaceReleasesNewPost(manualSpaceAllocationId, releaseDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpaceReleasesApi - object-oriented interface
 * @export
 * @class SpaceReleasesApi
 * @extends {BaseAPI}
 */
export class SpaceReleasesApi extends BaseAPI {
    /**
     * 
     * @summary Release the manually allocated space at wished date
     * @param {string} [manualSpaceAllocationId] The identifier of the manual space allocation which should be released
     * @param {string} [releaseDate] The release date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceReleasesApi
     */
    public v1SpaceReleasesSpaceReleasesNewPost(manualSpaceAllocationId?: string, releaseDate?: string, options?: any) {
        return SpaceReleasesApiFp(this.configuration).v1SpaceReleasesSpaceReleasesNewPost(manualSpaceAllocationId, releaseDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SsoApi - axios parameter creator
 * @export
 */
export const SsoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the SSO configurations based on the user email domain
         * @param {string} [email] User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoConfigGet: async (email?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Sso/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the SSO configuration based on SSO provider
         * @param {SsoProvider} provider Sso provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoProviderConfigGet: async (provider: SsoProvider, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('ssoProviderConfigGet', 'provider', provider)
            const localVarPath = `/Sso/{provider}/config`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate SSO code after SSO authentication and authenticate the user
         * @param {SsoCodeValidationRequest} [ssoCodeValidationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoValidateCodePost: async (ssoCodeValidationRequest?: SsoCodeValidationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Sso/validate-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ssoCodeValidationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SsoApi - functional programming interface
 * @export
 */
export const SsoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SsoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns the SSO configurations based on the user email domain
         * @param {string} [email] User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoConfigGet(email?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySsoConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoConfigGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the SSO configuration based on SSO provider
         * @param {SsoProvider} provider Sso provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoProviderConfigGet(provider: SsoProvider, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySsoConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoProviderConfigGet(provider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate SSO code after SSO authentication and authenticate the user
         * @param {SsoCodeValidationRequest} [ssoCodeValidationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoValidateCodePost(ssoCodeValidationRequest?: SsoCodeValidationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoValidateCodePost(ssoCodeValidationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SsoApi - factory interface
 * @export
 */
export const SsoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SsoApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns the SSO configurations based on the user email domain
         * @param {string} [email] User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoConfigGet(email?: string, options?: any): AxiosPromise<CompanySsoConfig> {
            return localVarFp.ssoConfigGet(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the SSO configuration based on SSO provider
         * @param {SsoProvider} provider Sso provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoProviderConfigGet(provider: SsoProvider, options?: any): AxiosPromise<CompanySsoConfig> {
            return localVarFp.ssoProviderConfigGet(provider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate SSO code after SSO authentication and authenticate the user
         * @param {SsoCodeValidationRequest} [ssoCodeValidationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoValidateCodePost(ssoCodeValidationRequest?: SsoCodeValidationRequest, options?: any): AxiosPromise<AuthTicket> {
            return localVarFp.ssoValidateCodePost(ssoCodeValidationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SsoApi - object-oriented interface
 * @export
 * @class SsoApi
 * @extends {BaseAPI}
 */
export class SsoApi extends BaseAPI {
    /**
     * 
     * @summary Returns the SSO configurations based on the user email domain
     * @param {string} [email] User email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public ssoConfigGet(email?: string, options?: any) {
        return SsoApiFp(this.configuration).ssoConfigGet(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the SSO configuration based on SSO provider
     * @param {SsoProvider} provider Sso provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public ssoProviderConfigGet(provider: SsoProvider, options?: any) {
        return SsoApiFp(this.configuration).ssoProviderConfigGet(provider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate SSO code after SSO authentication and authenticate the user
     * @param {SsoCodeValidationRequest} [ssoCodeValidationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public ssoValidateCodePost(ssoCodeValidationRequest?: SsoCodeValidationRequest, options?: any) {
        return SsoApiFp(this.configuration).ssoValidateCodePost(ssoCodeValidationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VehiclesApi - axios parameter creator
 * @export
 */
export const VehiclesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the driver vehicles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VehiclesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Vehicles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Insert a new vehicle for the driver
         * @param {NewVehicle} [newVehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VehiclesPost: async (newVehicle?: NewVehicle, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Vehicles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newVehicle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a vehicle for the driver
         * @param {string} vehicleId The vehicle you want delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VehiclesVehicleIdDelete: async (vehicleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('v1VehiclesVehicleIdDelete', 'vehicleId', vehicleId)
            const localVarPath = `/v1/Vehicles/{vehicleId}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a vehicle for the driver
         * @param {string} vehicleId The vehicle you want update
         * @param {UpdatedVehicle} [updatedVehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VehiclesVehicleIdPut: async (vehicleId: string, updatedVehicle?: UpdatedVehicle, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('v1VehiclesVehicleIdPut', 'vehicleId', vehicleId)
            const localVarPath = `/v1/Vehicles/{vehicleId}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatedVehicle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehiclesApi - functional programming interface
 * @export
 */
export const VehiclesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehiclesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns the driver vehicles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VehiclesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vehicle>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1VehiclesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Insert a new vehicle for the driver
         * @param {NewVehicle} [newVehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VehiclesPost(newVehicle?: NewVehicle, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1VehiclesPost(newVehicle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a vehicle for the driver
         * @param {string} vehicleId The vehicle you want delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VehiclesVehicleIdDelete(vehicleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1VehiclesVehicleIdDelete(vehicleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a vehicle for the driver
         * @param {string} vehicleId The vehicle you want update
         * @param {UpdatedVehicle} [updatedVehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VehiclesVehicleIdPut(vehicleId: string, updatedVehicle?: UpdatedVehicle, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1VehiclesVehicleIdPut(vehicleId, updatedVehicle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VehiclesApi - factory interface
 * @export
 */
export const VehiclesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehiclesApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns the driver vehicles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VehiclesGet(options?: any): AxiosPromise<Array<Vehicle>> {
            return localVarFp.v1VehiclesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Insert a new vehicle for the driver
         * @param {NewVehicle} [newVehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VehiclesPost(newVehicle?: NewVehicle, options?: any): AxiosPromise<VehicleResponse> {
            return localVarFp.v1VehiclesPost(newVehicle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a vehicle for the driver
         * @param {string} vehicleId The vehicle you want delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VehiclesVehicleIdDelete(vehicleId: string, options?: any): AxiosPromise<VehicleResponse> {
            return localVarFp.v1VehiclesVehicleIdDelete(vehicleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a vehicle for the driver
         * @param {string} vehicleId The vehicle you want update
         * @param {UpdatedVehicle} [updatedVehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VehiclesVehicleIdPut(vehicleId: string, updatedVehicle?: UpdatedVehicle, options?: any): AxiosPromise<VehicleResponse> {
            return localVarFp.v1VehiclesVehicleIdPut(vehicleId, updatedVehicle, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehiclesApi - object-oriented interface
 * @export
 * @class VehiclesApi
 * @extends {BaseAPI}
 */
export class VehiclesApi extends BaseAPI {
    /**
     * 
     * @summary Returns the driver vehicles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public v1VehiclesGet(options?: any) {
        return VehiclesApiFp(this.configuration).v1VehiclesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Insert a new vehicle for the driver
     * @param {NewVehicle} [newVehicle] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public v1VehiclesPost(newVehicle?: NewVehicle, options?: any) {
        return VehiclesApiFp(this.configuration).v1VehiclesPost(newVehicle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a vehicle for the driver
     * @param {string} vehicleId The vehicle you want delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public v1VehiclesVehicleIdDelete(vehicleId: string, options?: any) {
        return VehiclesApiFp(this.configuration).v1VehiclesVehicleIdDelete(vehicleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a vehicle for the driver
     * @param {string} vehicleId The vehicle you want update
     * @param {UpdatedVehicle} [updatedVehicle] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public v1VehiclesVehicleIdPut(vehicleId: string, updatedVehicle?: UpdatedVehicle, options?: any) {
        return VehiclesApiFp(this.configuration).v1VehiclesVehicleIdPut(vehicleId, updatedVehicle, options).then((request) => request(this.axios, this.basePath));
    }
}


